import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"

// import { gsap } from "gsap/dist/gsap";
import { withpath } from '../../utils/index.js'
import H1 from "./slide1h"
import Svg from '../../assets/s1_2.svg';
import Svgm from '../../assets/s1_2m.svg';

const txt = [
	"以及财富传承人想要给后代留下何种样貌的生存环境。道德心和业绩不再毫不相关，可持续投资在多元企业家的投资组合和资产中占据了更多的份额。对他们而言，可持续投资是必须具备的，而非只是锦上添花。事实上，普华永道的一项研究表明，中国有79%的家族企业会优先考虑可持续的商业行为，而美国只有23%。"
]



const Slide1_2 = (p) => {





	return (
		<section className={ss.slide} data-id={"1_2"}>
			<div 
id={`slidebg1_2`} 
			className={ss.slidebg} data-id={"1_2"} style={{backgroundImage: `url(${withpath(`/img/slide12.jpg`)})` }} />


			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"1_2"}>
				<div className={ss.svg} data-id={"1_2"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>
				<div 
					className={ss.simpletxt} 
					data-id={"1_2"} 
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="-400"
				/>
			</div>
		</section>
)}

export default Slide1_2