import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"
import { withpath } from '../../utils/index.js'
import H1 from "./slide4h"

const txt = [
	"<span>“</span>私人市场不仅拥有无需公开上市就能为公司融资的能力，还有能为提高效率而退市的优势，并且着眼于长期目标，因此在多元化投资组合中极具吸引力。”",
	"亨利·李（Henry Lee）, 汇丰环球私人银行委托管理投资组合及另类投资环球主管"
]

const Slide4_4 = (props) => {

	return (
		<section className={ss.slide} data-id={"4_4"}>
			
			<div id={`slidebg4_4`} className={ss.slidebg} data-id={"4_4"} style={{backgroundImage: `url(${withpath(`/img/slide44.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>

			<div data-swiper-parallax-scale="0.25"
				className={ss.quotetxt1} 
			>
				<div 
					className={ss.quotetxtblack}  
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="0"
				/>
			</div>

			<div 
				className={ss.quoteautor} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>

		</section>
)}

export default Slide4_4