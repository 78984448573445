import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"
// import { gsap } from "gsap/dist/gsap";
import { withpath } from '../../utils/index.js'
import H1 from "./slide2h"

const txt = [
	"除了大额投资人这一身份外，大多数国际化多元企业家还是积极的商业领袖和创业者，不断地在寻找新项目或令人兴奋的活动。他们中有许多人领导着全球化的团队，或为这些团队提供咨询。他们在发掘创业潜力方面也有很好的直觉，并且在获得了一两次成功后，会愿意支持自己或他人的创业想法——挥洒其不断增强的自信心和敏锐的判断力。",
	"正因如此，他们通常对多个创业项目感兴趣，并会以不同程度的积极性将这些项目加入",
	"其投资组合中。",
	"如何有条不紊地同时管理多个项目？"
]

const Slide2_0 = (p) => {

	return (
		<section className={ss.slide} data-id={"2_0"}>
			<div id={`slidebg2_0`} className={ss.slidebg} data-id={"2_0"} style={{backgroundImage: `url(${withpath(`/img/slide20.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"2_0"}>
				<div className={ss.txtwhite} dangerouslySetInnerHTML={{__html: txt[0] }}  data-swiper-parallax="400"/>
				<div className={ss.txtblackbg} dangerouslySetInnerHTML={{__html: txt[1] }}  data-swiper-parallax="200"/>
				<div className={ss.conturtxt1} data-swiper-parallax-scale="0.25">
					<span id="as20" className={ss.conturtxt1span} dangerouslySetInnerHTML={{__html: txt[2] }} />
				</div>
				<div className={ss.txtwhite2} dangerouslySetInnerHTML={{__html: txt[3] }} data-swiper-parallax="-400"/>
			</div>
		</section>
)}

export default Slide2_0