import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide4.module.css"

import H1 from "./slide4h"
import Svg from '../../assets/s4_2.svg';
import Svgm from '../../assets/s4_2m.svg';
import Svg2 from '../../assets/s4_21.svg';
import Svg2m from '../../assets/s4_21m.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"财富往往眷顾勇者，但多元企业家应该知道，越是高风险的行为越需要多元化，",
	"另类投资是个让人满意且明智的做法。预计到2023年，另类资产行业将增值至14兆美元，比2017年增长59%。"
]

const Slide4_2 = (p) => {

	return (
		<section className={ss.slide} data-id={"4_2"}>
			<div id={`slidebg4_2`} className={ss.slidebg} data-id={"4_2"} style={{backgroundImage: `url(${withpath(`/img/slide42.jpg`)})` }} />
			<div className={ss.h1} data-swiper-parallax="-900">
				<H1 />
			</div>
<div className={ss.txttxt} data-id={"4_2"}>
			<div 
				className={ss.simpletxt} 
				data-id={"4_2"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="400"
				data-swiper-parallax-opacity="0"
			/>

			<div 
				className={ss.simpletxt} 
				data-id={"4_21"} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
				data-swiper-parallax-opacity="0"
			/>

			<div className={ss.svg} data-id={"4_2"} data-swiper-parallax="200">
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
			</div>


			<div className={ss.svg} data-id={"4_22"} data-swiper-parallax="-200">
					<div className={ss.mob}>
						<Svg2m />
					</div>
					<div className={ss.desc}>
						<Svg2 />
					</div>
			</div>
</div>

		</section>
)}

export default Slide4_2