import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"

import H1 from "./slide3h"
import Svg from '../../assets/s3_2.svg';
import Svgm from '../../assets/s3_2m.svg';
import { withpath } from '../../utils/index.js'

const txt = [
	"据莱坊房地产的数据显示，在2020年，住宅市场价格最高的是<span>香港，而上海、深圳和北京都位居前十</span>, 使得这些经济机会涌动的地区更值关注。"
]

const Slide3_2 = (p) => {


	return (
		<section className={ss.slide} data-id={"3_2"}>
			
			<div id={`slidebg3_2`} className={ss.slidebg} data-id={"3_2"} style={{backgroundImage: `url(${withpath(`/img/slide32.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>
<div className={ss.txttxt} data-id={"3_2"}>
			<div className={ss.svg} data-id={"3_2"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"3_2"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="-400"
			/>
</div>
		</section>
)}

export default Slide3_2