import React from 'react'
import * as s from "./slide0.module.css"

const txt = [
	"国际化<span>多元企业家</span>",
	"的崛起",
]

const Slide1h = (props) => {
	return (
		<div className={s.h}>
			<div className={s.h_0} dangerouslySetInnerHTML={{__html: txt[0] }} id={`h0_0`} />
			<div className={s.h_1} dangerouslySetInnerHTML={{__html: txt[1] }} id={`h0_1`} />
			<div className={s.h_line} id={`h0_l`}/>
		</div>
)}

export default Slide1h