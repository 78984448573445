import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"

import H1 from "./slide3h"
import Svg from '../../assets/s3_0.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"国际化多元企业家是真正的全球化公民，他们有能力住在他们选择的任何地方，可以进行最大程度的远程工作，并往返于多个",
	`经常穿梭于各大洲之间的人必<span class="aaa">然</span>对投资房产更有兴趣，因此，他们中许多人都在世界各地拥有超过三套住宅。`
]

const Slide3_0 = (p) => {

	return (
		<section className={ss.slide} data-id={"3_0"}>
			
			<div id={`slidebg3_0`} className={ss.slidebg} data-id={"3_0"} style={{backgroundImage: `url(${withpath(`/img/slide30.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>


		<div className={ss.txttxt} data-id={"3_0"}>
			<div 
				className={ss.simpletxt} 
				data-id={"3_0"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="400"
			/>

			<div className={ss.svg} data-id={"3_0"}>
				<Svg />
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"3_02"} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>
		</div>
		</section>
)}

export default Slide3_0