import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"

import H1 from "./slide2h"
import { withpath } from '../../utils/index.js'
const txt = [
	"据报道，伊隆·马斯克（Elon Musk）投资了19家公司，而杰夫·贝索斯（Jeff Bezos）通过亚马逊拥有了旗下15家公司，同时他还以三种方式投资了40多家其他企业。",
	"他的风险投资公司Bezos Expeditions可在16家公司中获利，而他个人还另外投资了5家企业。"
]

const Slide2_1 = (props) => {

	return (
		<section className={ss.slide} data-id={"2_1"}>
			<div id={`slidebg2_1`} className={ss.slidebg} data-id={"2_1"} style={{backgroundImage: `url(${withpath(`/img/slide21.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"2_1"}>
				<div className={ss.midtxt} dangerouslySetInnerHTML={{__html: txt[0] }} data-swiper-parallax="400"/>
				<div className={ss.txt2} data-swiper-parallax="-400" dangerouslySetInnerHTML={{__html: txt[1] }} />
			</div>
		</section>
)}

export default Slide2_1