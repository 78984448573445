import React from 'react'
import * as s from "./slide2.module.css"

const txt = [
	"",
	"商业领袖",
	"和富有洞察力的创造者",
]

const Slide2h = (props) => {
	return (
		<div className={s.h}>
			<div className={s.h_0 + ` h0`} dangerouslySetInnerHTML={{__html: txt[0] }} />
			<div className={s.h_1 + ` h1`} dangerouslySetInnerHTML={{__html: txt[1] }} />
			<div className={s.h_line + ` hl`} />
			<div className={s.h_2 + ` h2`} dangerouslySetInnerHTML={{__html: txt[2] }} />
		</div>
)}

export default Slide2h