import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide4.module.css"
 
import H1 from "./slide4h"
import Svg from '../../assets/s4_1.svg';
import Svgm from '../../assets/s4_1m.svg';
import Svg2 from '../../assets/s4_12.svg';
import Svg2m from '../../assets/s4_12m.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"如果您觉得这种组合眼熟，那么您可能与那些投资人相似，他们于",
	"并在2020年12月21日，在特斯拉被纳入标普500指数时<span>收获了731%的涨幅，</span>尽管该汽车制造商<span>自2003年以来损失了近70亿美元，</span>并且其主要收入来自向竞争对手出售碳排放税收抵免，而非其自身汽车产品的销售。"
]

const Slide4_1 = (p) => {

	return (
		<section className={ss.slide} data-id={"4_1"}>
			
			<div id={`slidebg4_1`} className={ss.slidebg} data-id={"4_1"} style={{backgroundImage: `url(${withpath(`/img/slide41.jpg`)})` }} />
			
			<div id={`slidebg4_1m`} className={ss.slidebg} data-id={"4_1m"} style={{backgroundImage: `url(${withpath(`/img/slide41m.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>


<div className={ss.txttxt} data-id={"4_1"}>
			<div 
				className={ss.simpletxt} 
				data-id={"4_1"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="-600"
			/>

			<div className={ss.fbox}>
				
				<div className={ss.svg} data-id={"4_1"}
data-swiper-parallax-scale="0.25"
				>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>

				<div 
				 	data-swiper-parallax="400"
				 	data-swiper-parallax-opacity="0"
					className={ss.simpletxt} 
					data-id={"4_12"} 
					dangerouslySetInnerHTML={{__html: txt[1] }} 
				/>

			</div>

			<div className={ss.svg} data-id={"4_12"} data-swiper-parallax="-400">
					<div className={ss.mob}>
						<Svg2m />
					</div>
					<div className={ss.desc}>
						<Svg2 />
					</div>
			</div>
</div>



		</section>
)}

export default Slide4_1