import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide2h"
import { withpath } from '../../utils/index.js'
const txt = [
	"积极从事慈善事业和指导工作，并且在机械工程领域备受瞩目，",
	"安·玛丽·塞斯崔（Ann Marie Sastry）于2017年创立了Amesite。这是一家位于密歇根州的人工智能软件公司，专注于提高教育成果。而她之前创业建立的创新型电池公司Sakti3，于2015年被戴森收购，收购价达到了",
	"9千万美元。"
]

const Slide2_2 = (p) => {

	return (
		<section className={ss.slide} data-id={"2_2"}>
			<div id={`slidebg2_2`} className={ss.slidebg} data-id={"2_2"} style={{backgroundImage: `url(${withpath(`/img/slide22.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			
			<div className={ss.txttxt} data-id={"2_2"}>
				<div className={ss.txt} data-swiper-parallax="400">
					<span className={ss.txtblackbg2} dangerouslySetInnerHTML={{__html: txt[0] }} />
					<span className={ss.txtwhite} dangerouslySetInnerHTML={{__html: txt[1] }} />
				</div>
				<div 
					className={ss.conturtxt}
					data-swiper-parallax-scale="0.25"
					data-id={"2_2"}
				>
					<span id="as22" className={ss.conturtxt1span} dangerouslySetInnerHTML={{__html: txt[2] }} />
				</div>
			</div>
		</section>
)}

export default Slide2_2