import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"
 
import H1 from "./slide3h"
import Svg from '../../assets/s3_1.svg';
import Svgm from '../../assets/s3_1m.svg';
import { withpath } from '../../utils/index.js'

const txt=[
	"<span>但是在何处安置？</span> 什么会促使他们选择目的地？ 不同国家有各种不同的、为创业生态系统提供支持的能力，因此，成为全球化的 <span>商业“居民”</span> 就意味着，能够有效利用各国各大洲拥有的多元化态度、资源和基础设施的优势。",
	"这些国家都为处于成长期或已经成熟的企业提供各种机会。"
]

const Slide3_1 = (p) => {

	return (
		<section className={ss.slide} data-id={"3_1"}>
			
			<div id={`slidebg3_1`} className={ss.slidebg} data-id={"3_1"} style={{backgroundImage: `url(${withpath(`/img/slide31.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>
<div className={ss.txttxt} data-id={"3_1"}>
			<div 
				className={ss.simpletxt} 
				data-id={"3_1"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="400"
			/>

			<div className={ss.svg} data-id={"3_1"} data-swiper-parallax="0">
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"3_12"} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>
</div>
		</section>
)}

export default Slide3_1