import React, {useEffect, useState} from 'react'
import * as s from "./slider.module.css"

import { gsap } from "gsap/dist/gsap";
// import { withpath } from '../utils/index.js'

import Slide00 from './slides/slide0_0'

import H1 from './slides/slide1h'
import Slide10 from './slides/slide1_0'
import Slide11 from './slides/slide1_1'
import Slide12 from './slides/slide1_2'
// import Slide13 from './slides/slide1_3'
import Slide14 from './slides/slide1_4'

import H2 from './slides/slide2h'
import Slide20 from './slides/slide2_0'
import Slide21 from './slides/slide2_1'
import Slide22 from './slides/slide2_2'
import Slide23 from './slides/slide2_3'

import H3 from './slides/slide3h'
import Slide30 from './slides/slide3_0'
import Slide31 from './slides/slide3_1'
import Slide32 from './slides/slide3_2'
import Slide33 from './slides/slide3_3'

import H4 from './slides/slide4h'
import Slide40 from './slides/slide4_0'
import Slide41 from './slides/slide4_1'
import Slide42 from './slides/slide4_2'
// import Slide43 from './slides/slide4_3'
import Slide44 from './slides/slide4_4'

import { track } from "./../utils/track";
import { Waypoint } from 'react-waypoint';

import { Swiper, SwiperSlide } from "swiper/react";
import "./swiper.css"
import SwiperCore, { Parallax, Pagination, Navigation } from 'swiper';
SwiperCore.use([ Parallax, Pagination, Navigation ]);



const TXT=[
	"",
	"The Legacy Leaver",
	"The Business Leader",
	"The Hypermobile Global Resident",
	"The Diversified Asset Holder"
]


const Slider = (p) => {

	const [slide, setSlide] = useState(0);
	// useEffect(() => {
	
	// },[slide])
	const [firsttime, setFirsttime] = useState(true);
	const [firsttimeTrack, setFirsttimeTrack] = useState(true);
	const [shake, setShake] = useState(true);

	// console.log(p)


	useEffect(() => {
		// console.log('shake: ' + shake)
		if(!shake){
			let els = document.querySelectorAll('.swiper-button-next');
			for (let i = 0; i < els.length; i++) {
				els[i].classList.add('swiper-button-off');
			}
		}
	},[shake])

	function enter(n){
		
		if(firsttimeTrack){
			setFirsttimeTrack(false);
			track(`cta`,`cta`,`pageView`,`${TXT[p.dataid]} - slide 1_CN`);
		}

		let h0 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h0`)
		let h1 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h1`)
		let hl = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .hl`)
		let h2 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h2`)
		gsap.killTweensOf([h0, h1, hl, h2]);
		gsap.set(h0, {opacity: 0, x: 30})
		gsap.set(h1, {opacity: 0})
		gsap.set(hl, {opacity: 0, scaleX: 0})
		gsap.set(h2, {opacity: 0})
		let tl = gsap.timeline({ paused: false });
			tl.to(h0, {opacity: 1, x: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(h1, {opacity: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(hl, {opacity: 1, scaleX: 1, duration: 1.0, ease: `power1.out` }, 0.0);
			tl.to(h2, {opacity: 1, duration: 1, ease: `power1.out` }, 0.3);
	}
	function leave(n){
		let h0 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h0`)
		let h1 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h1`)
		let hl = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .hl`)
		let h2 = document.querySelector(`.` + s.paralaxh + `[data-id="${n}"] .h2`)
		gsap.killTweensOf([h0, h1, hl, h2]);
		gsap.to(h0, {opacity: 0, x: 30, duration: 0.25})
		gsap.to(h1, {opacity: 0, duration: 0.25})
		gsap.to(hl, {opacity: 0, scaleX: 0, duration: 0.25})
		gsap.to(h2, {opacity: 0, duration: 0.25})
	}

	return (
		<section className={s.slider} data-id={p.dataid}>

			<Swiper
				speed={1000}
				parallax={true}
				navigation={true}
				pagination={{ clickable: true }}
				className={"mySwiper"}
				data-id={p.dataid}
				spaceBetween={0}
				slidesPerView={1}
				loop={(p.dataid!==0)}
				onSlideChange={
					(s) => { 
						if(firsttime){
							setFirsttime(false)
						}else{
							if(s.realIndex !== slide){
								// console.log(s.realIndex)
								track(`cta`,`cta`,`pageView`,`${TXT[p.dataid]} - slide ${s.realIndex + 1}_CN`);
							}
							if(shake)
								setShake(false)
						}

						setSlide(s.realIndex)
					}
				}
				// onSwiper={(swiper) => console.log(swiper)}
			>

				{
					(p.dataid === 0) && 
					<>
						<SwiperSlide><Slide00 size={p.size} slide={slide} /></SwiperSlide>
					</>
				}
				{
					(p.dataid === 1) && 
					<>
						<SwiperSlide><Slide10 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide11 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide12 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide14 size={p.size} slide={slide} /></SwiperSlide>
					</>
				}
				{
					(p.dataid === 2) && 
					<>
						<SwiperSlide><Slide20 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide21 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide22 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide23 size={p.size} slide={slide} /></SwiperSlide>
					</>
				}
				{
					(p.dataid === 3) && 
					<>
						<SwiperSlide><Slide30 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide31 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide32 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide33 size={p.size} slide={slide} /></SwiperSlide>
					</>
				}
				{
					(p.dataid === 4) && 
					<>
						<SwiperSlide><Slide40 size={p.size} slide={slide} /></SwiperSlide>
						<SwiperSlide><Slide41 size={p.size} slide={slide}/></SwiperSlide>
						<SwiperSlide><Slide42 size={p.size} slide={slide} /></SwiperSlide>
						{/*
							<SwiperSlide><Slide43 size={p.size} slide={slide} /></SwiperSlide>
						*/}
						<SwiperSlide><Slide44 size={p.size} slide={slide} /></SwiperSlide>
					</>
				}


{/* headers */}

				{
					(p.dataid === 1) && 
						<Waypoint 
							onEnter={(p) => {enter(1)}} 
							onLeave={(p) => {leave(1)}} 
							bottomOffset={"20%"} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div
							slot="container-start"
							className={s.paralaxh}
							data-id={1}
							data-swiper-parallax="0%"
						>
							<H1 slide={slide} />
						</div>
						</Waypoint>
				}

				{
					(p.dataid === 2) && 
						<Waypoint 
							onEnter={(p) => {enter(2)}} 
							onLeave={(p) => {leave(2)}} 
							bottomOffset={"20%"} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div
							slot="container-start"
							className={s.paralaxh}
							data-id={2}
							data-swiper-parallax="0%"
						>
							<H2 slide={slide} />
						</div>
						</Waypoint>
				}

				{
					(p.dataid === 3) && 
						<Waypoint 
							onEnter={(p) => {enter(3)}} 
							onLeave={(p) => {leave(3)}} 
							bottomOffset={"20%"} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div
							slot="container-start"
							className={s.paralaxh}
							data-id={3}
							data-swiper-parallax="0%"
						>
							<H3 slide={slide} />
						</div>
						</Waypoint>
				}

				{
					(p.dataid === 4) && 
						<Waypoint 
							onEnter={(p) => {enter(4)}} 
							onLeave={(p) => {leave(4)}} 
							bottomOffset={"20%"} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div
							slot="container-start"
							className={s.paralaxh}
							data-id={4}
							data-swiper-parallax="0%"
						>
							<H4 slide={slide} />
						</div>
						</Waypoint>
				}

			</Swiper>

		</section>
)}

export default Slider